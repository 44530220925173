import axios from "axios";
import eventBus from "../../../../../architecture/EventBus";
import Events from "../../../types/Events";

function initMainDropEvent(element:HTMLElement, id:number){
    async function drop_handler2(event:any) {
        element.style.outline = "none";
        let taskId = event.dataTransfer.getData("application/my-app");
        let columnId = Number(event.dataTransfer.getData("application/columnId"));
  
        let ok = await axios.post("/board/replacetask",{boardId:id, taskId:taskId})
        if(ok){
            eventBus.dispatchEvent(Events.dropTask, {id:id, columnId: columnId, taskId:taskId})
        }
    }
  
    function onDragOver(event:any){
        // prevent default to allow drop
        event.preventDefault();
        element.style.outline = "3px dotted";
        event.dataTransfer.dropEffect = "move";
    }
  
    function onDragLeave(){
        element.style.outline = "none";
    }
  
    eventBus.unregisterHandler(Events.dropTask,onDragLeave);
    eventBus.registerHandler(Events.dropTask,onDragLeave);
  
    element.removeEventListener("dragover", onDragOver);
    element.addEventListener("dragover", onDragOver);
  
    element.removeEventListener("dragleave", onDragLeave);
    element.addEventListener("dragleave", onDragLeave);
  
    element.removeEventListener("drop", drop_handler2);
    element.addEventListener("drop", drop_handler2);
}

export default initMainDropEvent;