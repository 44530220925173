import { Sidebar } from "features/sidebar";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import projectStore from "./store/store";
import styles from "./Main.module.css";
import StylesBuilder from "architecture/styles/StylesBuilder";
import axios from "axios";
import Button from "shared/ui/buttons/classic/Button";
import Field from "shared/ui/field/classic/Field";
import Input from "shared/ui/input/classic/Input";
import Modal from "shared/ui/modal/classic/modal";

interface MainProps{
    children:any;
}

function Main(props:MainProps){
    const items = useMemo(()=>projectStore.data.map(d => {return {value:d.id, displayValue:d.name}}), [projectStore.data]);
    const [isOpenCreate, setOpenCreate] = useState<boolean>(false);

    useEffect(()=>{
        projectStore.load();
    },[])

    async function onCreate(value:string){
      const response = await axios.post("/board/createboard", {name:value});
      if(response.status === 200){
        projectStore.load();
      }
      setOpenCreate(false);
    }
  
    function close(){
      setOpenCreate(false);
    }

    function openCreate(){
        setOpenCreate(true);
    }

    return(
        <div className={styles.body}>
            <div>
                <Sidebar items={items} onCreateItem={openCreate}/>
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
            <ModalCreateBoard isOpen={isOpenCreate} onCreate={onCreate} onClose={close}/>
        </div>
    );
}


const ModalCreateBoard : React.FC<{isOpen:boolean, onCreate:(value:string)=>void, onClose:()=>void}> = ({isOpen, onCreate, onClose}) =>{
    const saveClassName = StylesBuilder.use().widthFull().row().justCenter().alignCenter().get();
    const [value, setValue] = useState<string>("");

    useEffect(()=>{
        setValue("");
    },[isOpen]);
  
    return(
      <Modal isOpen={isOpen} onClose={onClose} title={"Создание доски"}>
        <Field label="Название доски">
          <Input type="text" placeholder="Введите текст" value={value} onChange={(val) => setValue(val.target.value)}/>
        </Field>
        <div className={saveClassName}>
            <Button onClick={() => onCreate(value)}> Сохранить </Button>
        </div>
      </Modal>
    );
}   

export default observer(Main);