import ListStore from "./ListStore";

class ListClaster{
    listStores : Map<string, ListStore> = new Map<string, ListStore>();
    schema: string;
    
    constructor(schema:string){
        this.schema = schema;
    }

    new(schema:string){
        this.listStores = new Map<string, ListStore>();
        this.schema = schema;
    }

    addStore(key:string) : ListStore{
        const store = new ListStore(this.schema);
        this.listStores.set(key, store);
        return store;
    }

    loadAll(data:any) : Promise<void[]>{
        let prom : Promise<void>[] = [];
        this.listStores.forEach(l => prom.push(l.load(data)));
        return Promise.all(prom);
    }

    loadIn(key:string, data:any) : Promise<void>{
        const x = this.listStores.get(key);
        return x!.load(data);
    }
}

export default ListClaster;