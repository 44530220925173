import React from 'react';
import styles from "./Button.module.css";

interface ButtonProps{
    children : any; 
    onClick : ()=>void; 
    disabled?: true;
}

const Button = (props:ButtonProps) => {
  return (
    <button
      className={styles.button}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export default Button;