import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";

interface SidebarProps {
  items: { value: any; displayValue: string }[];
  onCreateItem: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ items, onCreateItem }) => { 
  const navigate = useNavigate();

  return (
    <div className={styles.sidebar}>
        <ul className={styles.list}>
          {items.map((item) => (
            <li key={item.value} className={styles.item} onClick={()=> navigate("/"+item.value)}>
              {item.displayValue}
            </li>
          ))}
        </ul>
        <button className={styles.button} onClick={onCreateItem}>
          Создать элемент
        </button>
    </div>
  );
};
export default Sidebar;