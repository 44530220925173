import StylesBuilder from "../../../../architecture/styles/StylesBuilder";

interface IHeaderProps{
    title: string
    children?: any
}

function HeaderPage(props:IHeaderProps){
    
    const wrraperClassName = StylesBuilder.use()
        .row()
        .justBetween()
        .alignCenter()
        .get();

    const childClassName = StylesBuilder.use()
        .row()
        .gap()
        .get();

    return(
        <div className={wrraperClassName}>
            <h3>{props.title}</h3>
            <div className={childClassName}>
                {props.children}
            </div>
        </div>
    )
}

export default HeaderPage;