import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { TaskColumn } from '../modules/kanban/features/column/Index';
import { TaskControl } from '../modules/kanban/features/task';
import BoardPage from '../pages/board/BoardPage';
import { createBrowserRouter, Route, Router, RouterProvider, Routes } from 'react-router-dom';
import loginStore from 'architecture/stores/LoginStore';
import LoginPage from 'pages/login/LoginPage';
import { observer } from 'mobx-react-lite';

function App() {
  return (
      <RoutesApp/>
  );
}


const RoutesApp = observer(()=>{
  return(
    <div className="App">
        {loginStore.loggedIn ?
          <Routes>
            <Route path={"/:id"} element={<BoardPage/>}/>
          </Routes>
          :
          <LoginPage/>
        }
    </div>
  );
});

export default App;
