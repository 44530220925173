import axios from "axios";
import { observer } from "mobx-react-lite";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Field from "shared/ui/field/classic/Field";
import Input from "shared/ui/input/classic/Input";
import Modal from "shared/ui/modal/classic/modal";
import Main from "template/main/Main";
import eventBus from "../../architecture/EventBus";
import StylesBuilder from "../../architecture/styles/StylesBuilder"
import { currentFilter, Events, SimpleFilter, TaskColumn, TaskControl, TaskModal, TaskModalMode } from "../../modules/kanban";
import Button from "../../shared/ui/buttons/classic/Button";
import { HeaderPage, ToolPanel } from "../../shared/ui/page";
import columnStore from "./stores/ColumnStore";
import tasksClaster from "./stores/TasksClaster";

function BoardPage(){
    const wrraperClassName = StylesBuilder.use().column().widthFull().heightFull().gap().content().get();
    const param = useParams<{id:string}>();


    useEffect(()=>{
        async function load(){
            await columnStore.load({id:Number(param.id)});
            if(!columnStore.data) return;
            for(let data of columnStore.data){
                let store = tasksClaster.addStore(data.id);
                store.load({boardId:data.id, filter:currentFilter.filter});
            }
        }

        function UpdateColumn(event:any){
            tasksClaster.loadIn(event.columnId, {boardId:event.columnId, filter:currentFilter.filter});
        }

        function useFilter(event:any){
            currentFilter.filter = event;
            for(let data of columnStore.data){
                tasksClaster.loadIn(data.id,{boardId:data.id, filter:event});
            }
        }

        async function loadColumns(){
            await columnStore.load({id:Number(param.id)});
        }

        eventBus.registerHandler(Events.saveTask, UpdateColumn);
        eventBus.registerHandler(Events.filtering, useFilter);
        eventBus.registerHandler(Events.updateColumn, loadColumns);

        load();

        return ()=>{
            eventBus.unregisterHandler(Events.saveTask, UpdateColumn);
            eventBus.unregisterHandler(Events.filtering, useFilter);
            eventBus.unregisterHandler(Events.updateColumn, loadColumns);
        }
    },[param.id])

    return(
        <Main>
            <div className={wrraperClassName}>
                <HeaderPage title="Доска">
                    <Button onClick={()=>{}}>
                        Создать задачу
                    </Button>
                </HeaderPage>
                <ToolPanel>
                    <SimpleFilter/>
                </ToolPanel>
                <Body/>
                <TaskModalControl/>
            </div>       
        </Main>
    )
}

const TaskModalControl = observer(()=>{
    const [modalIsOpen, setOpenModal] = useState<boolean>(false);
    const [entityModal, setEntityModal] = useState<number>(-1);
    const [columnModal, setColumnModal] = useState<number>(-1);
    const [modalMode, setMode] = useState<TaskModalMode>(TaskModalMode.Create);
    
    useEffect(()=>{
        function taskOnClick(event:any){
            setOpenModal(true);
            setEntityModal(event.id);
            setColumnModal(event.column);
            setMode(TaskModalMode.Edit);
        }

        function taskCreate(event:any){
            setOpenModal(true);
            setColumnModal(event.columnId);
            setMode(TaskModalMode.Create);
            console.log(event.columnId);
        }

        eventBus.registerHandler(Events.taskOnClick, taskOnClick);
        eventBus.registerHandler(Events.createTask, taskCreate);

        return () =>{
            eventBus.unregisterHandler(Events.taskOnClick, taskOnClick);
            eventBus.unregisterHandler(Events.createTask, taskCreate);
        }
    },[])

    return(
        <TaskModal 
            mode={modalMode} 
            onClose={()=>{setOpenModal(false)}} 
            isOpen={modalIsOpen}
            entity={entityModal}
            columnId={columnModal}
        />
    )
})

const Body = observer(()=>{
    const bodyClassName = StylesBuilder.use().row().gap().widthFull().flexBasisFull().get();

    useEffect(()=>{
        function columnUpdate(event:any){
            tasksClaster.loadIn(event.id, {boardId:event.id, filter:currentFilter.filter});
            tasksClaster.loadIn(event.columnId, {boardId:event.columnId, filter:currentFilter.filter});
        }
        eventBus.registerHandler(Events.dropTask, columnUpdate);

        return () => {
            eventBus.unregisterHandler(Events.dropTask, columnUpdate);
        }
    },[])

    return(
        <div className={bodyClassName}>
            {
                columnStore.data?.map(c=>
                    <TaskColumn title={c.name} entity={c.id}>
                        {tasksClaster.listStores.get(c.id)?.data?.map(t => 
                            <TaskControl 
                                assignee={t.user?.displayValue} 
                                priority={t.priority?.displayValue} 
                                labels={[t.label]} 
                                title={t.name} 
                                entity={t.id}  
                                columnId={c.id}                              
                            />    
                        )}
                    </TaskColumn>
                )
            }
            <CreateColumnControl/>
        </div>
    )
});

function CreateColumnControl(){
    const param = useParams<{id:string}>();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>("")
    

    function open(){
        setOpen(true);
        setValue("");
    }

    async function createColumn(){
        await axios.post("board/createcolumn", {Name:value, BoardId:param.id});
        await columnStore.load({id:param.id});
        setOpen(false);
    }

    const saveClassName = StylesBuilder.use().widthFull().row().justCenter().alignCenter().get();

    return (
        <>
            <Button onClick={open}>Создать колонку</Button>
            <Modal isOpen={isOpen} onClose={() => setOpen(false)} title={"Создание колонки"}>
                <Field label="Название колонки">
                    <Input type="text" placeholder="Введите текст" value={value} onChange={(val) => setValue(val.target.value)}/>
                </Field>
                <div className={saveClassName}>
                    <Button onClick={createColumn}> Сохранить </Button>
                </div>
            </Modal>
        </>
    )
}

export default observer(BoardPage);