import eventBus from 'architecture/EventBus';
import axios from 'axios';
import Events from 'modules/kanban/types/Events';
import React, { ReactNode, useEffect, useRef } from 'react';
import Button from 'shared/ui/buttons/classic/Button';
import Dropdown from 'shared/ui/dropdown/Dropdown';
import initMainDropEvent from '../core/InitMainDropEvent';
import styles from './TaskColumn.module.css';

type TaskColumnProps = {
  title: string;
  children: ReactNode;
  entity: number;
};

const options =[
  {value: 0, displayValue:"В лево"},
  {value: 1, displayValue:"В право"},
  {value: 2, displayValue:"Удалить"},
  {value: 3, displayValue:"Переменовать"}
]

const TaskColumn: React.FC<TaskColumnProps> = ({ title, children, entity }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(()=>{
    initMainDropEvent(ref.current!, entity);
  },[entity])
  
  function onCreateTask(){
    eventBus.dispatchEvent(Events.createTask, {columnId:entity});
  }

  async function onSelect(value:any){
    if(value === 0){
      await axios.post("/Board/replacecolumn",{id:entity, magnifier:-1});
      eventBus.dispatchEvent(Events.updateColumn, {magnifier:-1});
    }
    else if(value === 1){
      await axios.post("/Board/replacecolumn",{id:entity, magnifier:1});
      eventBus.dispatchEvent(Events.updateColumn, {magnifier:1});
    }
    
  }

  return (
    <div className={styles.taskColumn} ref={ref}>
      <h2 className={styles.taskColumnTitle}>{title} <Dropdown options={options} onSelect={onSelect}>Н</Dropdown></h2>
      <div className={styles.taskList}>{children}</div>
      <Button onClick={onCreateTask}>Создать задачу</Button>
    </div>
  );
};
export default TaskColumn;