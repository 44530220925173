import axios from "axios";
import { action, makeAutoObservable, makeObservable, observable } from "mobx";

class ListStore{
    data:any[] = [];
    count:number;
    schema: string;

    constructor(schema:string){
        this.schema = schema;
        this.count = 0;
        makeAutoObservable(this)
    }

    async load(data?:any){
        let result = await axios.post(this.schema,data);
        if (result.status == 200) {
            let json = await result.data;
            this.data = json.items;
            this.count = json.count;
        }
    }
}

export default ListStore;