import ListStore from "architecture/stores/ListStore";
import Lookup from "architecture/types/Lookup";

const labelsStore = new ListStore("board/getlookuplist");
const userStore = new ListStore("board/getlookuplist");
const priorityStore = new ListStore("board/getlookuplist");

const metaStore = {
    labels:labelsStore,
    users:userStore,
    prioritys: priorityStore,
    
    get(): [Lookup[],Lookup[],Lookup[]]{
        return [labelsStore.data as Lookup[], userStore.data as Lookup[], priorityStore.data as Lookup[]];
    },
    async load(){
        await labelsStore.load({name:"Labels"});
        await userStore.load({name:"Users"});
        await priorityStore.load({name:"Prioritys"});
    }
}

export default metaStore;