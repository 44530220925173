import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import eventBus from '../../../../../architecture/EventBus';
import Events from '../../../types/Events';
import initBlockDraggable from '../core/InitBlockDraggable';
import initMainDropEvent from '../core/InitMainDropEven';
import styles from '../css/TaskControl.module.css';

type TaskControlProps = {
  assignee: string;
  priority?: string;
  labels?: string[];
  title?: string;
  entity:number;
  columnId:number;
};

const TaskControl: React.FC<TaskControlProps> = ({ assignee, priority, labels, title, entity, columnId }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(()=>{
    initBlockDraggable(ref.current!, entity, columnId);
    initMainDropEvent(ref.current!, entity, columnId);
  },[entity])
  
  function onClick(){
    eventBus.dispatchEvent(Events.taskOnClick, {id:entity, column: columnId});
  }

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.taskControl} onClick={onClick}>
        <h2 className={styles.title}>{title}</h2>
        {assignee != null &&
        <div className={styles.assignee}>
          <span className={styles.label}>Assignee:</span>
          <span className={styles.value}>{assignee}</span>
        </div>
        }
        {priority != null &&
        <div className={styles.priority}>
          <span className={styles.label}>Priority:</span>
          <span className={styles.value}>{priority}</span>
        </div>
        }
        {labels![0] != null &&
        <div className={styles.labels} hidden={labels == null}>
          <span className={styles.label}>Labels:</span>
          <div className={styles.labelList}>
            {labels?.map((label) => (
              <span className={styles.labelItem} key={label}>
                {label}
              </span>
            ))}
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default TaskControl;