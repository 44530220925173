import eventBus from "architecture/EventBus";
import Lookup from "architecture/types/Lookup";
import { observer } from "mobx-react-lite";
import metaStore from "modules/kanban/entity/Meta";
import Events from "modules/kanban/types/Events";
import { useEffect, useState } from "react";
import Select from "shared/ui/select/classic/Select";
import styles from "./SimpleFilter.module.css";

const SimpleFilter : React.FC = () => {
    const categories = [
        {id:0, displayValue:"Ярлык", value:"Label"},
        {id:1, displayValue:"Исполнитель", value:"User"},
        {id:2, displayValue:"Приоритет", value:"Priority"}
    ]
    const [currentFilter, setCurrentFilter] = useState<Lookup[]>([]);
    const [filter, setFilter] = useState<Lookup | null>(null);
    const [category, setCategory] = useState<Lookup>(categories[0]);

    useEffect(()=>{
        async function load(){
            await metaStore.load();
            setCurrentFilter(metaStore.labels.data);
        }

        load();
    },[])

    function filtering(item: Lookup){
        setFilter(item);
        eventBus.dispatchEvent(Events.filtering, item ? {PropertyName: (category as any).value, value:item.id} :  null );
    }

    return(
        <div className={styles.simpleFilter}>
            <Select value={category?.id} options={categories} onChange={(value)=>{
                setCategory(value);
                setFilter({id:-1,displayValue:""});
                if(value.id === 0) setCurrentFilter(metaStore.labels.data);
                else if(value.id === 1) setCurrentFilter(metaStore.users.data);
                else if(value.id === 2) setCurrentFilter(metaStore.prioritys.data);  
            }}/>
            <Select isNone value={filter?.id} options={currentFilter} onChange={filtering}/>
        </div>
    )
}

export default observer(SimpleFilter);