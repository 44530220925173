function initBlockDraggable(element: HTMLElement, id:number, columnId:number) {
    element.setAttribute("draggable", "true");
  
    function dragstart_handler(ev:any) {
        element.style.outline = "3px dotted";
        ev.stopPropagation();
        ev.dataTransfer.setData("application/my-app", id);
        ev.dataTransfer.setData("application/columnId", columnId);
    }
  
    element.addEventListener("dragend", (event) => {
        // reset the transparency
        element.style.outline = "none";
    });
  
    element.removeEventListener("dragstart", dragstart_handler);
    element.addEventListener("dragstart", dragstart_handler);
}

export default initBlockDraggable;