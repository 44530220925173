import PageStore, { IEntityStore } from "architecture/stores/PageStore";
import Lookup from "architecture/types/Lookup";
import { makeAutoObservable } from "mobx";

export class TaskEntity implements IEntityStore{
    id: number = -1;
    boardId: number = -1;
    name: string = "";
    description: string = "";
    label: string = "";
    user : Lookup | null = null;
    priority : Lookup | null = null;
    
    constructor(){
        makeAutoObservable(this);
    }

    serialize() : object{
        return {
            id:this.id,
            boardId: this.boardId,
            name: this.name,
            description: this.description,
            label: this.label,
            userId: this.user?.id,
            priorityId: this.priority?.id
        };
    }

    deserialize(obj: any){
        this.id = obj.id;
        this.name = obj.name;
        this.label = obj.label;
        this.description = obj.description;
        this.priority = obj.priority;
        this.user = obj.user;
    }
}

const taskPageStore = new PageStore("board/taskinfo", "board/savetask", new TaskEntity());

export default taskPageStore;