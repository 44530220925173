import Lookup from 'architecture/types/Lookup';
import React, { useEffect } from 'react';
import styles from './Select.module.css';

type Option = {
  value: string;
  label: string;
};

type SelectProps = {
  options: Lookup[];
  value?: string | number;
  onChange: (item: Lookup) => void;
  isNone?:true;
};

const Select: React.FC<SelectProps> = ({ options, value, onChange, isNone }) => {

  function onChangeSelect(event: React.ChangeEvent<HTMLSelectElement>){
    let value = options.find(o => o.id == Number(event.target.value));
    onChange(value!);
  }

  return (
    <select
      className={styles.select}
      value={value}
      onChange={onChangeSelect}
    >
      {isNone && <option value={-1}>Не выбрано</option>}
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.displayValue}
        </option>
      ))}
    </select>
  );
};

export default Select;