import axios from "axios";
import { makeAutoObservable, makeObservable } from "mobx";

class PageStore<T extends IEntityStore> {
    url:string;
    saveUrl:string
    entity: T;

    constructor(url:string, saveUrl:string, entity:T) {
        this.url = url
        this.saveUrl = saveUrl;
        this.entity = entity;

        makeAutoObservable(this)
    }

    newEntity(entity:T){
        this.entity = entity;
    }

    async load() {
        const moduleId = {id: this.entity.id};
    
        let result = await axios.post(this.url, moduleId);
        if(result.status === 200) {
            this.entity!.deserialize(await result.data);
        }
    }

    async save(){
        const module = this.entity!.serialize();

        await axios.post(this.saveUrl, module);
    }
}

export interface IEntityStore{
    id:number;

    serialize : () => object;
    deserialize: (obj:any) => void;
}

export default PageStore;