import loginStore from "architecture/stores/LoginStore";
import StylesBuilder from "architecture/styles/StylesBuilder"
import axios from "axios";
import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import Button from "shared/ui/buttons/classic/Button";
import Field from "shared/ui/field/classic/Field";
import Input from "shared/ui/input/classic/Input";

const loginState = observable({
    login: "",
    password: "",

    onLogin:async () => {
        const result = await axios.post("/user/auth", {Login:loginState.login, Password:loginState.password});
        loginStore.logIn(result.data.jwt);
    }
});

function LoginPage(){
    const wrapperClass = StylesBuilder.use().widthFull().heightFull().alignCenter().justCenter().row().get();
    const bodyClass = StylesBuilder.use().content().column().alignCenter().justCenter().gap().get();
    const f = loginState;

    return(
        <div className={wrapperClass}>
            <div className={bodyClass}>
                <Field label={"Логин"}>
                    <Input type={"text"} placeholder={""} onChange={(e) => f.login = e.target.value} />
                </Field>
                <Field label={"Пароль"}>
                    <Input type={"password"} placeholder={""} onChange={(e) => f.password = e.target.value} />
                </Field>
                <Button onClick={f.onLogin}>Войти</Button>
            </div>
        </div>
    )
}

export default LoginPage;