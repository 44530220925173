import React, { useState, useRef, useEffect, ReactNode } from 'react';
import styles from './Dropdown.module.css';

type Option = {
  value: any;
  displayValue: string;
};

type Props = {
  options: Option[];
  onSelect: (value: any) => void;
  children: ReactNode;
};

const Dropdown: React.FC<Props> = ({ options, onSelect, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div className={styles.toggle} onClick={toggle}>
        {children}
      </div>
      {isOpen && (
        <div className={styles.menu}>
          {options.map(option => (
            <div
              key={option.value}
              className={styles.option}
              onClick={() => {
                onSelect(option.value);
                setIsOpen(false);
              }}
            >
              {option.displayValue}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;