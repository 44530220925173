import React, { ChangeEvent } from "react";
import StylesBuilder from "../../../../architecture/styles/StylesBuilder";
import styles from "./Field.module.css";

interface FieldProps {
  label: string;
  children:any;
}

const Field: React.FC<FieldProps> = ({ label, children}) => {
  const childrenClassNames = StylesBuilder.use().column().widthFull().get();
    return (
    <div className={styles.field}>
      <label className={styles.fieldLabel}>{label}</label>
      <div className={childrenClassNames}>{children}</div>
    </div>
  );
};

export default Field;