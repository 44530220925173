import eventBus from "architecture/EventBus";
import axios from "axios";
import { observer } from "mobx-react-lite";
import metaStore from "modules/kanban/entity/Meta";
import Events from "modules/kanban/types/Events";
import { useEffect } from "react";
import Button from "../../../../../shared/ui/buttons/classic/Button";
import Field from "../../../../../shared/ui/field/classic/Field";
import Input from "../../../../../shared/ui/input/classic/Input";
import Modal from "../../../../../shared/ui/modal/classic/modal";
import { ToolPanel } from "../../../../../shared/ui/page";
import Select from "../../../../../shared/ui/select/classic/Select";
import taskPageStore, { TaskEntity } from "../store/TaskPageStore";
import TaskModalMode from "../type/Mode";
import styles from "./TaskModal.module.css";

interface TaskModalProps{
    mode: TaskModalMode;
    onClose: () => void;
    isOpen: boolean;
    entity:number;
    columnId: number;
}

function TaskModal(props: TaskModalProps){
    const title = props.mode == TaskModalMode.Create ? "Создание задачи" : "Задача";

    const [labels, users, prioritys] = metaStore.get();

    useEffect(()=>{
        async function loadMeta() {
            await metaStore.load();
        }

        loadMeta();

        if(props.mode == TaskModalMode.Edit){
            const entity = new TaskEntity();
            entity.id = props.entity;
            entity.boardId = props.columnId;
            taskPageStore.newEntity(entity);
            taskPageStore.load();
        }
        else{
            const entity = new TaskEntity();
            taskPageStore.newEntity(entity);
            entity.boardId = props.columnId;
        }
    }, [props.entity, props.columnId])

    async function save(){
        if(props.mode == TaskModalMode.Edit){
            await taskPageStore.save();
            props.onClose();
            eventBus.dispatchEvent(Events.saveTask, {columnId:props.columnId});
        }
        else if(props.mode == TaskModalMode.Create){
            await axios.post("board/createtask", taskPageStore.entity.serialize())
            props.onClose();
            eventBus.dispatchEvent(Events.saveTask, {columnId:props.columnId});
        }
    }

    return (
        <Modal 
            isOpen={props.isOpen} 
            onClose={props.onClose} 
            title={title}
        >
            <Field label="Название">
                <Input type={"text"} placeholder={"Введите название"} value={taskPageStore.entity?.name} onChange={(event)=>{taskPageStore.entity!.name = event.target.value}} />
            </Field>
            <Field label="Ярлык">
                <Input type={"text"} placeholder={"Введите ярлык"} value={taskPageStore.entity?.label} onChange={(event)=>{taskPageStore.entity!.label = event.target.value}} />
            </Field>
            <Field label="Приоритет">
                <Select isNone options={prioritys} value={taskPageStore.entity?.priority?.id ?? -1} onChange={(item)=>{taskPageStore.entity!.priority = item}}/>
            </Field>
            <Field label="Исполнитель">
                <Select isNone options={users} value={taskPageStore.entity?.user?.id ?? -1} onChange={(item)=>{taskPageStore.entity!.user = item}}/>
            </Field>
            <textarea className={styles.text} value={taskPageStore.entity?.description} onChange={(event)=>{taskPageStore.entity!.description = event.target.value}}/>
            <ToolPanel>
                <Button onClick={save}>
                    Сохранить
                </Button>
                <Button onClick={props.onClose}>
                    Отмена
                </Button>
            </ToolPanel>
        </Modal>
    )
}

export default observer(TaskModal);