import axios from "axios";
import eventBus from "../../../../../architecture/EventBus";
import Events from "../../../types/Events";

function initMainDropEvent(element:HTMLElement, orderTask:number, newColumn:number){

    async function drop_handler2(event:any) {
        event.stopPropagation();
        let taskId = event.dataTransfer.getData("application/my-app");
        let columnId = Number(event.dataTransfer.getData("application/columnId"));
  
        let ok =  await axios.post("/Board/replacetask",{taskId:taskId, taskOrder:orderTask})
        if(ok){
          eventBus.dispatchEvent(Events.dropTask, {id:newColumn, columnId: columnId, taskId:taskId})
        }
        element.style.paddingTop = "0"
    }
  
    function onDragOver(event:any){
        // prevent default to allow drop
        event.preventDefault();
        element.style.paddingTop = "60px"
        event.dataTransfer.dropEffect = "move";
    }
  
    function onDragLeave(){
      element.style.paddingTop = "0"
    }
  
    element.removeEventListener("dragover", onDragOver);
    element.addEventListener("dragover", onDragOver);
  
    element.removeEventListener("dragleave", onDragLeave);
    element.addEventListener("dragleave", onDragLeave);
  
    element.removeEventListener("drop", drop_handler2);
    element.addEventListener("drop", drop_handler2);
}

export default initMainDropEvent;